document.addEventListener("DOMContentLoaded", function () {
	var loadMoreButton = document.getElementById("load-more-posts");

	if (loadMoreButton) {
		loadMoreButton.addEventListener("click", function () {
			var button = this;
			var page = parseInt(button.getAttribute("data-page"));
			var postFeed = button.closest(".wp-block-post-feed");
			var postTypes = postFeed.getAttribute("data-post-types");
			var postsPerPage = postFeed.getAttribute("data-posts-per-page");

			var xhr = new XMLHttpRequest();
			xhr.open("POST", bespokeThemeLoadMore.ajax_url, true);
			xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

			xhr.onreadystatechange = function () {
				if (xhr.readyState === 4) {
					if (xhr.status === 200) {
						var response = xhr.responseText;
						if (response) {
							button.setAttribute("data-page", page + 1);
							document.querySelector(".post-template").insertAdjacentHTML("beforeend", response);
							button.textContent = "Load More";
						} else {
							button.textContent = "No more posts";
						}
					}
				}
			};

			xhr.send(
				"action=bespoke_theme_load_more&nonce=" +
					bespokeThemeLoadMore.nonce +
					"&page=" +
					(page + 1) +
					"&post_types=" +
					postTypes +
					"&posts_per_page=" +
					postsPerPage,
			);

			button.textContent = "Loading...";
		});
	}
});
